.footer_main_component {
  background: #1a1a1a;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  width: 100%;
  .footer_sub_component {
    position: relative;
    width: 100%;
    z-index: 10;
    .footer_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .link_component {
        display: flex;
        align-items: center;
      }
    }
  }
}
