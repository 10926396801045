.unique_main_container {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
  background: #1a1a1a;
  .unique_sub_container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 56px;
    flex-direction: column;
    display: flex;
    .img_container {
      overflow-x: hidden;
      justify-content: center;
      flex: 1 1 0%;
      display: flex;
      top: 0;
      right: 0;
      left: 0;
      position: absolute;
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
    .unique_sub_child_container {
      text-align: center;
      gap: 1.5rem;
      flex-wrap: wrap;
      flex-direction: column;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      .btn_main_container {
        border: 1px solid rgba(162, 178, 237, 0.3);
        background: rgba(0, 0, 0, 0.08);
        box-shadow: rgba(128, 255, 204, 0.25) 0px 0px 8px 0px;
        border-radius: 9999px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        height: 2.75rem;
        max-width: fit-content;
        display: flex;
        margin: auto;
        position: relative;
        .btn_container {
          margin: auto;
          padding: 1px;
          background: radial-gradient(
              128.38% 113.75% at 53.93% -13.75%,
              rgba(0, 0, 0, 0) 0%,
              rgba(76, 158, 255, 0.2) 72%,
              rgba(128, 255, 204, 0.2) 94.5%
            ),
            rgb(0, 0, 0);
          border-radius: 9999px;
          text-align: center;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          max-width: max-content;
          display: inline-block;
          position: relative;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .text_container {
        h1 {
          opacity: 1;
          transform: none;
          font-weight: 700;
          font-size: 40px;
          margin-left: auto;
          margin-right: auto;
          max-width: 980px;
          margin-top: -1px;
          position: relative;
          color: aliceblue;
        }
      }
    }
    .card_main_component {
      border-radius: 0.5rem;
      gap: 0px;
      position: relative;
      max-width: 1200px;
      .blur_img_container {
        overflow-x: hidden;
        justify-content: center;
        display: flex;
        right: 0;
        left: 0;
        top: -16rem;
        position: absolute;
        img {
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
          height: auto;
        }
      }
      .card_comaponent {
        padding: 2rem;
        border-style: solid;
        border-width: 0.5px;
        border-color: #ffffff1a;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        position: relative;
        .card_sub_component {
          align-self: stretch;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          flex-grow: 1;
          flex: 1 1 0%;
          width: 100%;
          display: flex;
          position: relative;
          .card_data {
            color: rgb(255 255 255 / 1);
            font-weight: 700;
          }
          .card_detail {
            color: rgb(163 163 163 / 1);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 425px) {
  .unique_main_container {
    padding: 3rem 1.5rem 0;
    .unique_sub_container {
      .unique_sub_child_container {
        .text_container {
          h1 {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
