.main_form_container {
  background: #1a1a1a;
  padding: 3rem 3rem 0;
  .form_box_container {
    border-radius: 0px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: radial-gradient(
        112.32% 83.8% at 50.41% 50%,
        rgba(0, 0, 0, 0) 0%,
        rgba(152, 195, 189, 0.2) 100%
      ),
      rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    padding: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px){
  .main_form_container {
    padding: 3rem 1rem 0;
    .form_box_container {
      border-radius: 0px;
      padding: 6px;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
}