.dash_table {
  // color: $white;
  // text-decoration: none;
  display: grid;
  justify-content: center;

  ul {
    display: block;
  }
  a {
    color: black !important;
    text-decoration: none;
    // font-weight: bold;
    // letter-spacing: 2px;
  }
}
