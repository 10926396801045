.pricing_main_component {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
  background: #1a1a1a;
  .pricing_sub_component {
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: inline-flex;
    position: relative;
    .pricing_img_container {
      inset: auto;
      max-width: 100vw;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
      z-index: 0;
      position: absolute;
      @media only screen and (min-width: 426px) and (max-width: 600px) {
        top: -100px;
      }
    }
    .data_component {
      z-index: 1;
      gap: 3rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      display: flex;
      .btn_main_container {
        border: 1px solid rgba(162, 178, 237, 0.3);
        background: rgba(0, 0, 0, 0.08);
        box-shadow: rgba(128, 255, 204, 0.25) 0px 0px 8px 0px;
        border-radius: 9999px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        height: 2.75rem;
        max-width: fit-content;
        display: flex;
        margin: auto;
        position: relative;
        .btn_container {
          margin: auto;
          padding: 1px;
          background: radial-gradient(
              128.38% 113.75% at 53.93% -13.75%,
              rgba(0, 0, 0, 0) 0%,
              rgba(76, 158, 255, 0.2) 72%,
              rgba(128, 255, 204, 0.2) 94.5%
            ),
            rgb(0, 0, 0);
          border-radius: 9999px;
          text-align: center;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          max-width: max-content;
          display: inline-block;
          position: relative;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .title1 {
        color: white;
        font-size: 34px;
        font-weight: 800;
      }
      .title2 {
        color: rgb(165 243 252 / 1);
        font-size: 34px;
        font-weight: 800;
      }
      .sub_text_main {
        max-width: 533px;
        text-align: center;
        position: relative;
        .text_main {
          z-index: 2;
          color: rgb(212 212 212 / 1);
        }
      }
      .data_info {
        opacity: 1;
        transform: none;
        padding-top: 0.55rem;
        padding-bottom: 0.55rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        gap: 1rem;
        justify-content: center;
        background-color: rgb(255 255 255 / 0.1);
        border-radius: 100px;
        align-items: center;
        position: relative;
        display: inline-flex;
      }
      .contact_main_container {
        gap: 0.75rem;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        display: inline-flex;
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .pricing_main_component {
    padding: 0;
    .pricing_sub_component {
      padding: 1rem 0.5rem 0;
      .pricing_img_container {
        top: -5rem;
      }
      .data_component {
        gap: 1rem;
        .title_main_container {
          flex-direction: column;
          .title1 {
            font-size: 1rem;
            line-height: 1.5rem;
            display: flex;
            justify-content: center;
          }
          .title2 {
            font-size: 1rem;
            line-height: 1.5rem;
            display: flex;
            justify-content: center;
          }
        }
        .sub_text_main {
          width: 50%;
        }
        .data_info {
          .text {
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 425px) {
  .pricing_main_component {
    // padding-top: 0;
    // padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    .pricing_sub_component {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .pricing_img_container {
        top: -4.5rem;
        // img {
        //   min-width: 360px;
        //   max-width: 100%;
        // }
      }
      .data_component {
        gap: 2rem;
        .title_main_container {
          justify-content: center;
          display: flex;
          flex-direction: column;
        }

        .sub_text_main {
          width: 60%;
          min-width: 220px;
          .extra {
            position: absolute;
            right: 0;
            left: 0;
            z-index: 0;
            top: -1.25rem;
            bottom: -2.25rem;
            background: black;
            filter: blur(20px);
          }
          .text_main {
            z-index: 2;
            color: rgb(212 212 212 / 1);
            position: relative;
          }
        }
        .title1 {
          font-size: 18px;
          display: flex;
          justify-content: center;
        }
        .title2 {
          font-size: 18px;
          display: flex;
          justify-content: center;
        }
        .data_info {
          // .icon {
          //   display: none;
          // }
          .text {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .pricing_main_component {
    padding: 3rem 0;
    .pricing_sub_component {
      padding: 3rem 1.5rem 0;
      .data_component {
        gap: 2rem;
        .title_main_container {
          flex-direction: column;
          .title1 {
            font-size: 1.5rem;
            line-height: 2rem;
            display: flex;
            justify-content: center;
          }
          .title2 {
            font-size: 1.5rem;
            line-height: 2rem;
            display: flex;
            justify-content: center;
          }
        }
        .sub_text_main {
          width: 60%;
          .extra {
            position: absolute;
            right: 0;
            left: 0;
            z-index: 0;
            top: -1.25rem;
            bottom: -2.25rem;
            background: black;
            filter: blur(20px);
          }
          .text_main {
            z-index: 2;
            color: rgb(212 212 212 / 1);
            position: relative;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1220px) {
  .pricing_main_component {
    .data_component {
      gap: 1rem !important;
      .title1 {
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .title2 {
   
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
