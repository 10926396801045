.dash_table {
  // color: $white;
  // text-decoration: none;
  display: grid;
  justify-content: start;
  align-items: start;

  ul {
    display: flex;
    width: 100%;
  }
  a {
    width: 100%;
    color: black !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    // font-weight: bold;
    // letter-spacing: 2px;
  }
}
