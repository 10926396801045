.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control {
  padding: 14px 13px;
  border: 0.0625rem solid #dbe2ed;
  color: #363636;
}

.unorder {
  border: none;
}

.nav-link {
  color: #43454a;
  font-size: 1.286rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border-color: transparent !important;
}
.nav-link.active {
  position: relative;
}
.nav-link.active::before {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 50%;
  /* background: #a368ff !important; */
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}


@keyframes Gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
.block {
  position: relative;
  /* width: 100px; 
  height: 100px; */
  background: linear-gradient(0deg, #000, #272727);
  z-index: 2;
  border-radius: 16px;

}

.block::before, .block::after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg, 
    #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, 
    #fb0094, #0000ff, #00ff00, #ffff00, #ff0000
  );
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
  border-radius: 16px;

}

.block::after {
  filter: blur(50px);
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}


/* Define the initial CSS variables */
:root {
  --angle: 90deg;
  --gradX: 50%;
  --gradY: 0%;
  --c1: #02f6f6;  /* Replace with your desired color */
  --c2: #00ffff;  /* Replace with your desired color */
  --d: 5s;         /* Set your animation duration */
}

/* Style for the .box class */
.box {
  font-size: 3vw;
  border: 0.35rem solid;
  padding: 1vw;
  border-radius: 20px; /* Apply rounded corners to the box */

  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
}

/* Keyframe animation for rotating the border */
@keyframes borderRotate {
  0% {
    --angle: 90deg;  /* Starting angle */
  }
  100% {
    --angle: 450deg;  /* Smooth full rotation (5 full rotations) */
  }
}

/* Keyframe animation for changing the gradient (background position) */
@keyframes borderRadial {
  0% {
    --gradX: 50%;
    --gradY: 0%;
  }
  20% {
    --gradX: 80%;
    --gradY: 20%;
  }
  40% {
    --gradX: 100%;
    --gradY: 50%;
  }
  60% {
    --gradX: 50%;
    --gradY: 80%;
  }
  80% {
    --gradX: 20%;
    --gradY: 100%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}

/* Example usage of the gradient animation */
.box {
  animation: borderRotate var(--d) linear infinite, borderRadial 10s ease-in-out infinite;
}