::selection {
  color: red;
}

::-moz-selection {
  color: red;
}

.poster {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.congrats-text {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2rem;
  position: absolute;
  color: #4caf50;
  bottom: 20px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

#drawing_canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.austin {
  color: white;
  text-align:center;
  text-transform: uppercase;
  font-size: 2rem;


}