.main_container {
  background: transparent; // Solid black background for depth
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .form_box_container {
    width: 100%;
    max-width: 450px; // Limiting width to keep it compact
    padding: 2rem;
    border-radius: 12px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05); // A transparent glass-like look
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5); // Fancy shadow for depth
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    @media (max-width: 768px) {
      padding: 1rem; /* Reduced from 1.5rem */
    }

    @media (max-width: 480px) {
      padding: 0.5rem; /* Reduced further for small screens */
      max-width: 350px; // Narrow for mobile screens
    }

    // Add some spacing between form fields
    & > form {
      display: flex;
      flex-direction: column;
      gap: 4px; // Equal space between form elements
    }

    .btn_main_container {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      .btn_container {
        min-width: 180px;
        padding: 10px 20px;
        background: linear-gradient(
          135deg,
          rgba(76, 158, 255, 0.4) 0%,
          rgba(128, 255, 204, 0.2) 100%
        );
        border: none;
        border-radius: 50px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        text-transform: uppercase;
        cursor: pointer;
        transition: background 0.4s ease-in-out;

        &:hover {
          background: linear-gradient(
            135deg,
            rgba(128, 255, 204, 0.4) 0%,
            rgba(76, 158, 255, 0.2) 100%
          );
        }
      }
    }
  }
}

.MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 14px;
  text-transform: uppercase;
}

.MuiSelect-root,
.MuiOutlinedInput-root {
  color: rgba(255, 255, 255, 0.9) !important;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  font-size: 14px;

  & fieldset {
    border-color: rgba(255, 255, 255, 0.3) !important;
  }

  &:hover fieldset {
    border-color: rgba(255, 255, 255, 0.7) !important;
  }
}

.MuiPhone-root {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  color: white;
  font-size: 14px;
}

.MuiFormHelperText-root {
  font-size: 12px;
  color: rgba(255, 50, 50, 0.8) !important;
}

@media (max-width: 480px) {
  .MuiFormHelperText-root {
    font-size: 10px;
  }
  .form_box_container {
    // Add some spacing between form fields
    & > form {
      padding: 8px;
      gap: 4px !important; // Equal space between form elements
    }
  }
}
