.main_usecase_container {
  background: #1a1a1a;
  background-image: url(../../../assets/images/Homepage/usecase.svg);
  // background-size: auto 100%;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  justify-content: center;
  /* margin-top: 2rem; */
  position: relative;
  .sub_usecase_container {
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 6rem;
    overflow-x: hidden;
    row-gap: 1rem;
    justify-content: center;
    flex-direction: column;
    max-width: 90vw;
    width: 100%;
    display: flex;
    position: relative;
    .btn_main_container {
      border: 1px solid rgba(162, 178, 237, 0.3);
      background: rgba(0, 0, 0, 0.08);
      box-shadow: rgba(128, 255, 204, 0.25) 0px 0px 8px 0px;
      border-radius: 9999px;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 2.75rem;
      max-width: fit-content;
      display: flex;
      margin: auto;
      position: relative;
      .btn_container {
        margin: auto;
        padding: 1px;
        background: radial-gradient(
            128.38% 113.75% at 53.93% -13.75%,
            rgba(0, 0, 0, 0) 0%,
            rgba(76, 158, 255, 0.2) 72%,
            rgba(128, 255, 204, 0.2) 94.5%
          ),
          rgb(0, 0, 0);
        border-radius: 9999px;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        max-width: max-content;
        display: inline-block;
        position: relative;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    h1 {
      opacity: 1;
      transform: none;
      font-size: 3rem;
      line-height: 1;
      text-align: center;
      background: linear-gradient(110deg, #fff 18.62%, #afdee9 121.64%);
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    p {
      max-width: 640px;
      opacity: 1;
      transform: none;
      text-align: center;
      margin: auto;
      font-size: 14px;
      color: rgb(188 197 204 / 1);
    }
    .chip_main_container {
      width: 70%;
      display: flex;
      justify-content: center;
    }
    .divider_container {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        #72c9ce,
        rgba(0, 0, 0, 0)
      );
      opacity: 0.25;
      width: 100%;
      height: 1px;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .main_usecase_container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .sub_usecase_container {
    padding-top: 4rem;
  }
  
  h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 480px) {
  .main_usecase_container {
    padding-top: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .sub_usecase_container {
    padding-top: 3rem;
  }
  
  h1 {
    font-size: 2rem !important;
    line-height: 1.15 !important;
  }
  
  p {
    font-size: 12px;
  }
  
  .chip_main_container {
    width: 100% !important;
  }
}