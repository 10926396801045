.frequently_main_component {
  background: #1a1a1a;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  justify-content: center;
  width: 100%;
  display: flex;
  .frequently_sub_component {
    padding-left: 4rem;
    padding-right: 4rem;
    gap: 10rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
    flex-direction: row;
    margin: 5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    flex: 1 1 0%;
    display: flex;
    h3 {
      font-size: 2.25rem;
      font-weight: 600;
      color: white;
      max-width: 440px;
      display: flex;
      flex-wrap: wrap;
    }
    .accordion_box {
      width: 60%;
      .accordion_main {
        color: white;
        background: #1a1a1a;
        padding: 0.5rem 0;
        border-bottom: 1px solid white;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .frequently_main_component {
    padding-left: 0;
    padding-right: 0;
    .frequently_sub_component {
      padding-bottom: 5rem;
      padding-top: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      gap: 2.5rem;
      margin: 1.25rem;
      flex-direction: column;
      h3 {
        font-size: 1.25rem;
        margin: 0;
      }
      .accordion_box {
        width: 100%;
      }
    }
  }
}
