.btn_main_container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .btn_container {
    min-width: 180px;
    padding: 10px 20px;
    background-color: #DE283B;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
    cursor: pointer;
    transition: background 0.4s ease-in-out;
  }
}
