.main_container {
  height: calc(100vh + 125px);
  background: #1a1a1a;
  .main_box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    h1 {
      font-size: 64px !important;
      margin-bottom: 1rem;
      font-weight: 600;
      padding: 0.5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    p {
      margin-bottom: 4rem;
      max-width: 36rem;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      span {
        font-size: 1.25rem;
        letter-spacing: 0.048px;
        opacity: 0.8;
      }
    }
    .mike_container {
      display: flex;
      justify-content: center;
      margin-top: 0.25rem;
      height: 86px;
      animation: MoveUpDown 6s linear infinite alternate;
      img {
        height: 100%;
      }
    }
    .give_btn_cotainer {
      display: flex;
      justify-content: center;
      margin-top: 0.55rem;
      margin-bottom: 5rem;
      .give_btn {
        background: #1a1a1a;

        letter-spacing: 0.2px;
        box-shadow: rgba(128, 255, 204, 0.2) 0px 0px 8px 0.5px;
        min-width: 82px;
        opacity: 1;
        transform: none;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        border-width: 1px;
        justify-content: center;
        // height: 2rem;
        border-radius: 16px;
        color: white;
        text-align: center;
        h2 {
          padding: 4px;
          text-align: center;
          margin: auto;
          justify-content: center;
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main_container {
    padding: 0 1.5rem;
    .main_box {
      display: flex;
      h1 {
        font-size: 2.25rem !important;
      }
      p span {
        font-size: 1rem;
      }
      .Try_btn {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
}

@media only screen and (min-width: 960px) and (max-width: 1280px) {
}
@media only screen and (min-width: 1281px) and (max-width: 1536px) {
}
@media only screen and (min-width: 1536px) and (max-width: 2560px) {
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 600px) {
  .main_container {
    padding: 0 1.5rem; // Reduced padding for smaller screens
    .main_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        margin-top: 3.75em;
        font-size: 2rem !important; // Smaller font for mobile
      }
      p span {
        font-size: 0.75rem; // Adjusted text size for mobile
      }
      .mike_container {
        height: 70px; // Reduced height for smaller devices
      }
      .give_btn_cotainer {
        margin-bottom: 3rem; // Reduced bottom margin
        .give_btn {
          h2 {
            font-size: 1rem;
          }
        }
      }
      .Try_btn {
        display: flex;
        justify-content: center;
        margin-top: 1rem; // Added space between buttons
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .main_container {
    padding: 0 2rem; // Padding for medium screens
    .main_box {
      h1 {
        font-size: 3.5rem !important; // Slightly larger font for tablets
      }
      p span {
        font-size: 1.1rem; // Adjusted text size for tablets
      }
      .mike_container {
        height: 80px; // Slightly bigger container for tablet
      }
      .give_btn {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
}
