@import "../../assets/scss/style.scss";
.login_container {
    display: flex;
    justify-content: center;
    padding-top: 14.5rem;
    .login_Box {
        background-color: white;
        width: 360px;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
        padding: 2rem;
        color: $steel_grey;
        letter-spacing: 0;
        line-height: 1.5rem;
        font-size: 1rem;
        border-radius: 0.5rem;
    }
    @media (max-width: 350px) {
        .login_Box {
            padding: 1rem;
        }
    }
}