.main_aimade_container {
  background-image: url(../../../assets/images/Homepage/aimade.svg);
  background-size: cover;
  background-position: center center;
  padding-left: 3rem;
  padding-right: 3rem;
  background: #1a1a1a;
  padding-top: 3rem;
  justify-content: center;
  display: flex;
  max-width: 100vw;
  position: relative;
  .child_aimade_container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    row-gap: 1rem;
    flex-direction: column;
    justify-content: center;
    max-width: 80rem;
    width: 100%;
    display: flex;
    position: relative;
    .btn_main_container {
      border: 1px solid rgba(162, 178, 237, 0.3);
      background: rgba(0, 0, 0, 0.08);
      box-shadow: rgba(128, 255, 204, 0.25) 0px 0px 8px 0px;
      border-radius: 9999px;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 2.75rem;
      max-width: fit-content;
      display: flex;
      margin: auto;
      position: relative;
      .btn_container {
        margin: auto;
        padding: 1px;
        background: radial-gradient(
            128.38% 113.75% at 53.93% -13.75%,
            rgba(0, 0, 0, 0) 0%,
            rgba(76, 158, 255, 0.2) 72%,
            rgba(128, 255, 204, 0.2) 94.5%
          ),
          rgb(0, 0, 0);
        border-radius: 9999px;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        max-width: max-content;
        display: inline-block;
        position: relative;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    h1 {
      line-height: 3rem;
      opacity: 1;
      transform: none;
      font-weight: 700;
      text-align: center;
      max-width: 56rem;
      margin-top: 1rem;
      margin: auto;
      font-size: 2.25rem;
      margin-top: 1rem;
      background: linear-gradient(110deg, #fff 18.62%, #afdee9 121.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .card_main_component {
      border-radius: 40px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      background: radial-gradient(
          112.32% 83.8% at 50.41% 50%,
          rgba(0, 0, 0, 0) 0%,
          rgba(152, 195, 189, 0.1) 100%
        ),
        rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(4px);
      padding: 4rem;
      flex-direction: row;
      gap: 2rem;
      justify-content: center;
      max-width: 80rem;
      width: 100%;
      display: flex;
      margin-top: 2rem;
      .img_container {
        z-index: -1;
        top: -10rem;
        overflow: hidden;
        height: 250px;
        right: auto;
        left: auto;
        position: absolute;
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }
    .aimade_first_card {
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.1);
      flex: 1 1 0%;
      padding: 0.5rem;
      display: flex;
      position: relative;
      .topimage {
        img {
          top: -60px;
          right: -7%;
          height: 140px;
          user-select: none;
          z-index: -1;
          opacity: 1;
          position: absolute;
          max-width: 100%;
          display: block;
        }
      }
      .bottomimage {
        img {
          bottom: -60px;
          height: 140px;
          user-select: none;
          z-index: -1;
          opacity: 1;
          position: absolute;
          max-width: 100%;
          display: block;
        }
      }
      .first_card_main {
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(4px);
        flex-direction: column;
        padding: 2rem;
        width: 100%;
        display: flex;
        height: 100%;
      }
    }
    .second_card_main {
      flex: 1 1 0%;
      display: flex;
      position: relative;
      .second_card_sub_conatiner {
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.25);
        flex: 1 1 0%;
        padding: 0.5rem;
        display: flex;
        position: relative;
        .second_card {
          border-radius: 20px;
          border: 1px solid rgba(148, 255, 210, 0.8);
          background: radial-gradient(
              72.24% 136.19% at 52.51% 0%,
              rgba(0, 0, 0, 0) 0%,
              rgba(15, 32, 28, 0.08) 57.49%,
              rgba(128, 209, 255, 0.2) 90.03%,
              rgba(128, 255, 171, 0.2) 100%
            ),
            rgba(0, 0, 0, 0.95);
          backdrop-filter: blur(4px);
          padding: 2rem;
          flex-direction: column;
          width: 100%;
          display: flex;
        }
      }
    }
  }
}

@mixin cardTextStyles {
  font-weight: 500;
  font-size: 1.25rem;
  color: white;
}

@mixin cardH {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  color: white;
}
@mixin cardP {
  font-size: 14px;
  margin-bottom: 0;
  color: rgb(188 197 204 / 1);
}
@mixin cardText {
  margin-top: 1.5rem;
  color: rgb(188 197 204 / 1);
  justify-content: space-between;
}

.aimade_first_card .first_card_main {
  .card_text {
    @include cardTextStyles;
    // Additional styles specific to first_card_main
  }
  h3 {
    @include cardH;
    // Additional styles specific to first_card_main
  }
  p {
    @include cardP;
    // Additional styles specific to first_card_main
  }
  .text_component {
    @include cardText;
    // Additional styles specific to first_card_main
  }
}

.second_card_main .second_card {
  .card_text {
    @include cardTextStyles;
    // Additional styles specific to second_card
  }
  h3 {
    @include cardH;
    // Additional styles specific to first_card_main
  }
  p {
    @include cardP;
    // Additional styles specific to first_card_main
  }
  .text_component {
    @include cardText;
    // Additional styles specific to first_card_main
  }
}

@media only screen and (min-width: 300px) and (max-width: 425px) {
  .main_aimade_container {
    padding: 3rem 1.5rem 0;
    .child_aimade_container {
      h1 {
        font-size: 2rem;
      }
      .card_main_component {
        padding: 1rem;
      }
    }
  }
}
